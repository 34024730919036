@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  direction: rtl;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}


* {
  font-family: "Tajawal", sans-serif;
  color: #004556;
}

.calc100 {
  height: calc(100vh - 68px);
}

img {
  max-width: 100%
}

.componentsHome::-webkit-scrollbar {
  width: 8px;
  border-radius: 50px;
}

.componentsHome::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 50px;
} 

.componentsHome::-webkit-scrollbar-track {
  background-color: #cecece;
}

.shadow {
  box-shadow: "0px 2px 7px 0px rgba(18, 18, 18, 0.15)";
}


.calcSection {
  height: calc(100% - 120px);
}

.ltr {
  direction: ltr
}

.rtl {
  direction: rtl;
}

.active {
  background-color: #00B0DC !important;
  border-radius: 10px;
  color: #fff;
}

.active span {
  color: #fff;
}

.chats::-webkit-scrollbar {
  width: 8px;
  border-radius: 50px;
}

.chats::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 50px;
}

.chats::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}

fieldset {
  display: none;
}
select {
  appearance: none;
}
