.dropul::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  border-radius: 50px;
}

.dropul::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 50px;
}

.dropul::-webkit-scrollbar-track {

  background-color: #cecece;
}